import React from 'react';
import { Castle, Dashboard, Face, Settings, Toll, VideoCameraFront } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Divider, IconButton, List, SwipeableDrawer } from '@mui/material';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { Routes } from '~constants/routes';

import { message } from '~helper/_common';

import { AppMenuItem } from '~components/organism/AppMenuItem';
import { RequireRole } from '~components/utils/RequireRole';

import style from './AppMenu.module.scss';

export type AppMenuProps = {
    className?: string;
    isOpen: boolean;
    onChange: (isOpen: boolean) => void;
};

export const AppMenu: React.FC<AppMenuProps> = ({
    className,
    isOpen,
    onChange,
}) => {
    const router = useRouter();

    const handleClose = () => {
        onChange(false);
    };

    const handleOpen = () => {
        onChange(true);
    };

    const handleMenu = (path: string) => (event: React.MouseEvent | undefined): void => {
        event?.preventDefault();
        onChange(false);
        router.push(path);
    };

    return (
        <SwipeableDrawer
            data-test="appMenu"
            className={ clsx(style.root, className) }
            anchor="left"
            open={ isOpen }
            classes={ {
                paper: clsx(style.root__paper),
            } }
            onClose={ handleClose }
            onOpen={ handleOpen }>
            <div className={ style.root__head }>
                <IconButton onClick={ handleClose } data-test="appMenu.closeButton" size="large">
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider className={ style.root__divider } />
            <List>
                <AppMenuItem
                    data-test="appMenu.list.dashboard"
                    onClick={ handleMenu(Routes.ROOT) }
                    highlightedAt={ [ Routes.ROOT ] }
                    icon={ <Dashboard /> }>
                    { message('menu.dashboard') }
                </AppMenuItem>
                <AppMenuItem
                    data-test="appMenu.list.creators"
                    onClick={ handleMenu(Routes.CREATORS) }
                    highlightedAt={ [ Routes.CREATORS, Routes.CREATOR_PROFILE ] }
                    icon={ <Face /> }
                >
                    { message('menu.creators') }
                </AppMenuItem>
                <RequireRole roles={ [ 'ADMIN', 'SUPERUSER', 'COMPENSATION_MANAGER' ] }>
                    <AppMenuItem
                        data-test="appMenu.list.brandVideos"
                        onClick={ handleMenu(Routes.BRAND_VIDEOS) }
                        highlightedAt={ [ Routes.BRAND_VIDEOS ] }
                        icon={ <VideoCameraFront /> }>
                        { message('menu.brandVideos') }
                    </AppMenuItem>
                </RequireRole>
                <AppMenuItem
                    data-test="appMenu.list.payoutCycles"
                    onClick={ handleMenu(Routes.PAYOUT_CYCLES_OVERVIEW) }
                    highlightedAt={ [ Routes.PAYOUT_CYCLES_OVERVIEW, Routes.PAYOUT_CYCLES_RUN_DETAILS ] }
                    icon={ <Toll /> }>
                    { message('menu.payoutCycles') }
                </AppMenuItem>
                <AppMenuItem
                    data-test="appMenu.list.compensation"
                    onClick={ handleMenu(Routes.COMPENSATION) }
                    highlightedAt={ [ Routes.COMPENSATION ] }
                    icon={ <Settings /> }>
                    { message('menu.compensation') }
                </AppMenuItem>
                <RequireRole roles={ [ 'ADMIN' ] }>
                    <AppMenuItem
                        data-test="appMenu.list.administration"
                        onClick={ handleMenu(Routes.ADMINISTRATION) }
                        highlightedAt={ [ Routes.ADMINISTRATION ] }
                        icon={ <Castle /> }>
                        { message('menu.administration') }
                    </AppMenuItem>
                </RequireRole>
            </List>
        </SwipeableDrawer>
    );
};
