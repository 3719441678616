import { Roles } from '~constants/roles';

import { useOIDC } from '~modules/oidc';

export type RequireRoleProps = {
    children: React.ReactNode;
    roles: Array<Roles>;
    fallbackComponent?: React.ReactElement;
}

export const RequireRole: React.FC<RequireRoleProps> = ({
    children,
    roles,
    fallbackComponent
}) => {
    const oidc = useOIDC();
    const userRoles = oidc?.user?.profile?.roles || [];

    if (roles.some(role => userRoles.includes(role))) {
        return <>{ children }</>;
    }

    return fallbackComponent ? <>{ fallbackComponent }</> : null;
};
