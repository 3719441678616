import React, { forwardRef, memo } from 'react';
import { Paper as MuiPaper, PaperProps as MuiPaperProps } from '@mui/material';
import clsx from 'clsx';

import { ComponentCommonProps } from '~components/types';

import style from './Paper.module.scss';

export type PaperProps = ComponentCommonProps & MuiPaperProps;

export const Paper: React.FC<PaperProps> = memo(forwardRef(({
    children,
    className,
    'data-test': dataTest = 'paper',
    ...rest
}, ref) => {
    return (
        <MuiPaper
            elevation={ 0 }
            className={ clsx(style.root, className) }
            data-test={ dataTest }
            { ...rest }
            ref={ ref }>
            { children }
        </MuiPaper>
    );
}));

Paper.displayName = 'Paper';
