import { Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';

import { ComponentCommonProps } from '~components/types';

export type TextProps = ComponentCommonProps & {
    children: React.ReactNode;
    component?: React.ElementType;
    variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
};

export const Text: React.FC<TextProps> = ({
    children,
    className,
    component,
    variant = 'body1',
    'data-test': dataTest = 'text',
}) => {
    return (
        <Typography
            variant={ variant }
            // @ts-ignore
            component={ component }
            className={ className }
            data-test={ dataTest }
        >
            { children }
        </Typography>
    );
};
