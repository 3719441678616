import { AccountCircle } from '@mui/icons-material';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, Popover } from '@mui/material';

import { Routes } from '~constants/routes';

import { isMessageKey, message, MessageKey } from '~helper/_common';

import { useMenuState } from '~hooks/useMenuState';

import { useOIDC } from '~modules/oidc';

import { Button } from '~components/atoms/Button';
import { Headline } from '~components/atoms/Headline';
import { Text } from '~components/atoms/Text';

import style from './UserMenu.module.scss';

export const UserMenu: React.FC = () => {
    const oidc = useOIDC();
    const isLoggedIn = !!oidc?.user;
    const { menuAnchorElement, setMenuAnchorElement } = useMenuState(null);
    const isUserMenuOpen = !!menuAnchorElement;

    if (!isLoggedIn) {
        return null;
    }

    const handleOpen = (event: React.MouseEvent | undefined): void => {
        if (event) {
            event.preventDefault();
            setMenuAnchorElement(event.currentTarget);
        }
    };

    const handleClose = () => {
        setMenuAnchorElement(null);
    };

    return (
        <div data-test="userMenu">
            <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                data-test="userMenu.button"
                onClick={ handleOpen }
                color="inherit"
                variant="text">
                <AccountCircle className={ style.root__userIcon } />
                { oidc?.user?.profile.name }
            </Button>
            <Popover
                data-test="userMenu.popOver"
                id="menu-appbar"
                open={ isUserMenuOpen }
                anchorEl={ menuAnchorElement }
                onClose={ handleClose }
                anchorOrigin={ {
                    vertical: 'top',
                    horizontal: 'right',
                } }
                transformOrigin={ {
                    vertical: 'top',
                    horizontal: 'right',
                } }
            >
                <div>
                    <div className={ style.root__userMenu }>
                        <Headline variant="h6" className={ style.root__userMenu__headline } data-test="userMenu.name">
                            { oidc?.user?.profile.name }
                        </Headline>
                        { oidc?.user?.profile?.roles?.map((role: string) => (
                            <Text
                                component="div"
                                variant="caption"
                                key={ role }
                                data-test="userMenu.role">
                                { isMessageKey(`user.role.${role}`) ? message(`user.role.${role}` as MessageKey) : message('user.role.unknown') }
                            </Text>
                        )) }
                    </div>
                    <List className={ style.root__userMenu__actions } data-test="userMenu.actions">
                        <ListSubheader>{ message('userMenu.actions') }</ListSubheader>
                        <ListItem
                            button
                            data-test="userMenu.logout"
                            href={ Routes.LOGOUT }
                            component="a">
                            <ListItemIcon>
                                <MeetingRoomOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={ message('userMenu.logout') } />
                        </ListItem>
                    </List>
                </div>
            </Popover>
        </div>
    );
};
