import { Compensation } from '~api/model/compensation';

import { CreatorLevel } from '~constants/creatorLevel';

export enum PayoutCycleStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    UPCOMING = 'UPCOMING',
}

export enum PayoutMonthlyRhythm {
    MONTHLY = 'MONTHLY',
    EVERY_EVEN_MONTH = 'EVERY_EVEN_MONTH',
    EVERY_ODD_MONTH = 'EVERY_ODD_MONTH',
    BEGIN_OF_QUARTER = 'BEGIN_OF_QUARTER',
    MID_OF_QUARTER = 'MID_OF_QUARTER',
    END_OF_QUARTER = 'END_OF_QUARTER',
}

export type PayoutCycle = {
    payoutCycleId: string;
    payoutCycleName: string;
    predecessor: string | null;
    validFrom: string;
    validTo: string | null;
    payoutDatesOfMonth: Array<number>;
    payoutMonthlyRhythm: PayoutMonthlyRhythm;
    creators: Array<string>;
    defaultPayoutCycle: boolean;
    blocked: boolean;
    status: PayoutCycleStatus;
    nextPayoutDate: string | null;
};

export type PayoutCycleOverview = Array<PayoutCycle>;

export enum PayoutCycleRunStatus {
    CALCULATED = 'CALCULATED',
    CANCELLED = 'CANCELLED',
    ESTIMATED = 'ESTIMATED',
    ON_HOLD = 'ON_HOLD',
    PAID_OUT = 'PAID_OUT',
    RESUMED = 'RESUMED',
}

export type PayoutCycleRun = {
    payoutCycleRunId: string | null;
    payoutDate: string | null;
    blocked: boolean;
    status: PayoutCycleRunStatus;
    creators: {
        total: number;
        blocked: number;
    };
    views: {
        total: number;
        liveViews: number;
        replayViews: number;
    };
    beautyPoints: {
        total: number;
        liveViews: number;
        replayViews: number;
    };
    cash: {
        total: number;
        liveViews: number;
        replayViews: number;
    };
};

export type PayoutCycleRunsOverview = {
    upcoming: PayoutCycleRun | null;
    completed: Array<PayoutCycleRun>;
};

export type BillingPeriod = {
    start: string | null;
    end: string | null;
}

export type PayoutCycleRunDetails = {
    payoutCycleName: string;
    overview: PayoutCycleRun;
    compensation: Compensation;
    billingPeriod: BillingPeriod;
};

export enum PayoutStatus {
    CALCULATED = 'CALCULATED',
    CANCELLED = 'CANCELLED',
    ESTIMATED = 'ESTIMATED',
    ON_HOLD = 'ON_HOLD',
    ON_HOLD_BY_PAYOUT_RUN = 'ON_HOLD_BY_PAYOUT_RUN',
    PAID_OUT = 'PAID_OUT',
    RESUMED = 'RESUMED',
    STOPPED = 'STOPPED',
}

export enum PayoutMethod {
    CASH = 'CASH',
    BEAUTY_POINTS = 'BEAUTY_POINTS',
}

export type PayoutCycleRunCreator = {
    payoutCycleRunId: string;
    creatorId: string;
    levelId: CreatorLevel;
    nickname: string | null;
    email: string | null;
    beautyPointsPaidOut: number;
    cashPaidOut: number;
    blocked: boolean;
    payoutStatus: PayoutStatus;
    payoutMethod: PayoutMethod;
    liveViewCount: number;
    replayViewCount: number;
    billingPeriodStart: string | null;
    billingPeriodEnd: string | null;
    isCreditNoteDocumentDraftAvailable: boolean;
};

export type PayoutCycleRunCreatorOverview = Array<PayoutCycleRunCreator>;

export type CreatorPayoutCycleRun = {
    payoutCycle: string | null;
    payoutCycleRunId: string | null;
    payoutDate: string | null;
    levelId: CreatorLevel;
    blocked: boolean;
    payoutMethod: PayoutMethod;
    views: {
        total: number;
        liveViews: number;
        replayViews: number;
    };
    beautyPoints: {
        total: number;
        liveViews: number;
        replayViews: number;
    };
    cash: {
        total: number;
        liveViews: number;
        replayViews: number;
    };
    payoutStatus: PayoutStatus;
    billingPeriodStart: string | null;
    billingPeriodEnd: string | null;
    isCreditNoteDocumentDraftAvailable: boolean;
}

export type PayoutCycleRunTableData = Partial<PayoutCycleRun & CreatorPayoutCycleRun>;
