import { useEffect } from 'react';
import { NextRouter, useRouter } from 'next/router';

export const useRouterIsReadyEffect = (effect: (router: NextRouter) => void): void => {
    const router = useRouter();

    useEffect(() => {
        if (router.isReady) {
            effect(router);
        }
    }, [ router.isReady ]);
};
