import HelpIcon from '@mui/icons-material/Help';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import clsx from 'clsx';

import { getFormattedEnvironment, message } from '~helper/_common';
import { getBusinessDocLink, navigateToHome } from '~helper/_url';

import { useAppMenuOpen } from '~hooks/useAppMenuOpen';

import { Button } from '~components/atoms/Button';
import { Headline } from '~components/atoms/Headline';
import { ScrollToTop } from '~components/atoms/ScrollToTop';
import { AppMenu } from '~components/organism/AppMenu';
import { UserMenu } from '~components/organism/UserMenu';

import style from './Layout.module.scss';

export type LayoutProps = {
    children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({
    children,
}) => {
    const { isAppMenuOpen, setAppMenuOpen } = useAppMenuOpen();

    return (
        <>
            <AppMenu className={ style.root__appMenu } isOpen={ isAppMenuOpen } onChange={ setAppMenuOpen } />
            <div className={ style.root } data-test="layout">
                <header className={ style.root__header }>
                    <AppBar className={ style.root__header__appbar }>
                        <Toolbar data-test="layout.topBar">
                            <IconButton
                                color="inherit"
                                aria-label="open navigation"
                                onClick={ () => { setAppMenuOpen(!isAppMenuOpen); } }
                                edge="start"
                                data-test="layout.menuButton"
                                className={ clsx(style.root__header__menuButton) }
                                size="large">
                                <MenuIcon />
                            </IconButton>
                            <Box display={ { xs: 'none', md: 'block ' } }>
                                <img
                                    src="/douglas_logo.svg"
                                    alt="logo"
                                    onClick={ () => navigateToHome() }
                                    data-test="layout.topBar.logo"
                                    className={ style.root__header__logo } />
                            </Box>
                            <Box display={ { xs: 'block', md: 'none ' } }>
                                <img
                                    src="/douglas_icon.svg"
                                    alt="logo" onClick={ () => navigateToHome() }
                                    data-test="layout.topBar.logoIcon"
                                    className={ style.root__header__logoIcon } />
                            </Box>
                            <Headline variant="h6" className={ style.root__header__title } data-test="layout.topBar.title">
                                { message('title', getFormattedEnvironment()) }
                            </Headline>
                            <Button
                                aria-label="help"
                                data-test="layout.topBar.help"
                                color="inherit"
                                href={ getBusinessDocLink() }
                                target="_blank"
                                variant="text">
                                <HelpIcon className={ style.root__header_help } />
                            </Button>
                            <UserMenu data-test="layout.topBar.userMenu" />
                        </Toolbar>
                    </AppBar>
                    <Toolbar id="back-to-top-anchor" data-test="layout.scrollToTopTarget" />
                </header>
                <main className={ style.root__content } data-test="layout.content">
                    { children }
                </main>
                <footer className={ style.root__footer } data-test="layout.footer">
                    <span className={ style.root__footer__text }>{ message('footer.poweredBy') }</span>
                    <img src="/neuland_logo_small.svg" alt="logo" className={ style.root__footer__logo } />
                </footer>
                <ScrollToTop />
            </div>
        </>
    );
};
