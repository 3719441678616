import React from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab, useScrollTrigger } from '@mui/material';
import Zoom from '@mui/material/Zoom';

import style from './ScrollToTop.module.scss';

export const ScrollToTop: React.FC = () => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
        // @ts-ignore
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={ trigger }>
            <div onClick={ handleClick } role="presentation" className={ style.root } data-test="scrollToTop">
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </div>
        </Zoom>
    );
};
