import { forwardRef, memo } from 'react';
import { Button as MaterialButton, ButtonProps as MaterialButtonProps } from '@mui/material';

import { ComponentCommonProps } from '~components/types';

export type ButtonProps = ComponentCommonProps & MaterialButtonProps & {
    target?: string;
}

export const Button: React.FC<ButtonProps> = memo(forwardRef(({
    children,
    variant = 'contained',
    'data-test': dataTest = 'button',
    ...rest
}, ref) => {
    return (
        <MaterialButton
            variant={ variant }
            data-test={ dataTest }
            ref={ ref }
            { ...rest }>
            { children }
        </MaterialButton>
    );
}));

Button.displayName = 'Button';
