import { useState } from 'react';

export type UseToggleAllEventsVisible = {
    isHighlighted: boolean;
    setHighlighted: (isHighlighted: boolean) => void;
}

export const useAppMenuItemHighlighted = (): UseToggleAllEventsVisible => {
    const [ isHighlighted, setHighlighted ] = useState<boolean>(false);

    return { isHighlighted, setHighlighted };
};
