import { useEffect } from 'react';

import { isBrowser } from '~helper/_environment';
import { useUserManager } from '~helper/_openid-user-manager';

import { useOIDC } from '~modules/oidc';

import { LoadingPage } from '~components/pages/Loading';

// type definitions
export type AuthenticationPropsType = {
    children: React.ReactNode;
};

// implementation
export const Authentication: React.FC<AuthenticationPropsType> = ({
    children,
}) => {
    const userManager = useUserManager();
    const { isLoadingUser, user } = useOIDC();
    const isLoggedOut = !user;

    useEffect(() => {
        if (!isLoadingUser && isLoggedOut && userManager) {
            userManager.signinRedirect({ state: isBrowser() && window?.location.pathname });
        }
    });

    if (isLoggedOut) {
        return <LoadingPage />;
    }

    return <>{ children }</>;
};
