import { useState } from 'react';

export type MaybeMenuElement = Element | null;

export type UseMenuState = {
    menuAnchorElement: MaybeMenuElement;
    setMenuAnchorElement: (menuElement: MaybeMenuElement) => void;
}

export const useMenuState = (initialState: MaybeMenuElement): UseMenuState => {
    const [ menuAnchorElement, setMenuAnchorElement ] = useState<MaybeMenuElement>(initialState);

    return {
        menuAnchorElement,
        setMenuAnchorElement,
    };
};
