import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';

import { Routes } from '~constants/routes';

import { useAppMenuItemHighlighted } from '~hooks/useAppMenuItemHighlighted';
import { useRouterIsReadyEffect } from '~hooks/useRouterIsReadyEffect';

import style from './AppMenuItem.module.scss';

export type AppMenuItemProps = {
    onClick: (event: React.MouseEvent | undefined) => void;
    children: string;
    icon: React.ReactNode;
    highlightedAt: Routes[];
}

export const AppMenuItem: React.FC<AppMenuItemProps> = ({
    onClick,
    children,
    icon,
    highlightedAt,
}) => {
    const { isHighlighted, setHighlighted } = useAppMenuItemHighlighted();

    useRouterIsReadyEffect(({ route }) => {
        const highlighted = highlightedAt.some(
            (routePrefix) => {
                if (route === Routes.ROOT && routePrefix === Routes.ROOT) {
                    return true;
                } else if (routePrefix !== Routes.ROOT) {
                    return route.startsWith(routePrefix);
                }

                return false;
            });

        if (highlighted) {
            setHighlighted(true);
        }
    });

    return (
        <ListItem button onClick={ onClick } data-test="appMenuItem" className={ clsx(style.root, { [style.root__highlighted]: isHighlighted }) } >
            <ListItemIcon className={ style.root__icon } data-test="appMenuItem.icon">
                { icon }
            </ListItemIcon>
            <ListItemText
                className={ style.root__text }
                data-test="appMenuItem.text"
                primary={ children } />
        </ListItem>
    );
};
