import { useState } from 'react';

export type UseAppMenuOpen = {
    isAppMenuOpen: boolean;
    setAppMenuOpen: (open: boolean) => void;
}

export const useAppMenuOpen = (): UseAppMenuOpen => {
    const [ isAppMenuOpen, setAppMenuOpen ] = useState<boolean>(false);

    return {
        isAppMenuOpen,
        setAppMenuOpen,
    };
};
